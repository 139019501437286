export const maps = [
    "1200000020000000000000000001100000011000000000000000000300000031",
    "2000000300100100011001100000000000000000011001100010010020000003",
    "0000000000000000011100100121301001021310010011100000000000000000",
    "0000000000000000001011000121001001310010001011000000000000000000",
    "0000000000002000001111000100100001001000001111000000300000000000",
    "0100010000101000000100000012100000131000000100000010100001000100",
    "0000000000000000002101000010100000010100001013000000000000000000",
    "0000000001000000021010100000010000100000010101300000001000000000",
    "0000000001010010001001000021101001011300001001000100101000000000",
    "0010100000200100001000000000010000100000000001000010030000010100",
    "0000000000000000001111000001030000201000001111000000000000000000",
    "2000000000000000000110000010010000100100000110000000000000000003",
    "0000000000000010031101000100100001001200001101000000001000000000",
    "0000000000000000021101000000010001000010010113000000000000000000",
    "0000000000000000000110000013210000123100000110000000000000000000",
    "2000000000000000000001000000100000010000001000000000000000000003",
    "0000100000000100000210000001001000100100000010000000310000001000",
    "0000000000000000001001000021100000011300001001000000000000000000",
    "2000000000000000000000000010110000110100000000000000000000000003",
    "2100000001001100010100100103101001012010010010100011001000000013",
    "1000000101000010001221000030030000300300001221000100001010000001",
    "2100000001001100010100100103101001012010010010100011001000000013",
    "3021030220301203000100003020130230210302000010003021030220301203",
    "1001100110011001210000132100001321000013210000131001100110011001",
    "3000000013011110010000100101001001001010010000100111102100000002",
    "0310001033100011110100000010100000010100000010111100012201000120",
    "2121131300000000000000002121131321211313000000000000000021211313",
    "0000000021000000210101112101000000000000000101300001013000000130",
    "2000000300011000001111000033220000332200001111000001100020000003",
    "2000000300011000001111000033220000332200001111000001100020000003"
];
